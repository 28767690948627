import React from 'react'
import './styles.css'

const portfolio = () => {
  return (
    <div>
      <div id='redirector'>

        <h1>
          Under Build Will be live soon...
        </h1>
        <button>
          <a href="/Portfolio/webProjects">Web Projects</a>
        </button>
        <button>
          <a href="/Portfolio/DigitalMarketing">Digital Marketing</a>
        </button>
      </div>
    </div>
  )
}

export default portfolio